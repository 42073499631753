<style lang="less" scoped>
.user_box {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.user_content {
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .table-box {
        flex: 1;
    }
}
.select-box {
    max-height: 400px;
    overflow: hidden;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.link_box {
    display: flex;
    .title {
        flex: 0 0 60px;
    }
    .link {
        flex: 1;
        width: 0;
    }
}
.link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #409eff;
    &:hover {
        text-decoration: underline;
    }
}
.select_group_title {
    padding: 10px 0;
    margin-top: 10px;
    color: #8590b3;
}
</style>
<style lang="less" scoped>
.qr_box {
    display: flex;
    .qr {
        flex: 0 0 145px;
        box-sizing: border-box;
        padding: 20px;
        background-image: url(../../assets/images/qr_box.png);
        background-size: cover;
    }
    .qr_txt {
        flex: 1;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}
.group-content {
    height: 400px;
    display: flex;
    flex-direction: column;
    .select-box {
        flex: 1;
    }
}
</style>
<template>
    <div class="user_box">
        <el-dialog
            title="分享授权"
            :visible.sync="showQR"
            width="500px"
            :destroy-on-close="true"
            @close="closetaskQr"
        >
            <div class="qr_box">
                <div class="qr">
                    <vue-qr
                        :text="shareUrl + userKey"
                        :size="125"
                        :margin="0"
                    ></vue-qr>
                </div>
                <div class="qr_txt">
                    <div>授权即代表您同意通过火键系统：</div>
                    <div>
                        1.往您的抖音账号发布公开作品（发布内容、频次由您通过火键系统操作设置
                    </div>
                    <div>
                        2.删除你抖音账号下的发布内容（由您通过火键系统操作确认）
                    </div>
                    <div>
                        3.获取您的抖音账号信息、视频数据、评论、粉丝画像等
                    </div>
                </div>
            </div>
            <div class="link_box">
                <div class="title">授权链接</div>
                <div :titile="shareUrl" class="link" @click="openUrl()">
                    {{ shareUrl }}
                </div>
            </div>
        </el-dialog>
        <el-dialog
            title="派发任务授权"
            :visible.sync="showTaskQR"
            :destroy-on-close="true"
            @close="closetaskQr"
            width="240px"
        >
            <PROXY_AREA @cityCode="getCode" type="2" />
            <!-- {{ authState }} -->
            <div
                class="qr-box"
                v-loading="authState == 0"
                :element-loading-text="cookie_txt"
                element-loading-background="rgba(0, 0, 0, 0.8)"
            >
                <div v-if="authState == 2">已扫码，等待授权</div>
                <div v-if="authState == 3">
                    <i class="el-icon-check"></i>
                    <div>授权成功</div>
                </div>
                <div v-if="authState == 5">
                    <i class="el-icon-close"></i>
                    <div>授权失败</div>
                </div>
                <div v-if="authState == '-1'">
                    <i class="el-icon-close"></i>
                    <div>授权码异常</div>
                </div>
                <img :src="authQr" alt="" width="200" />
            </div>
        </el-dialog>
        <PLATFORM :platform="platform" @platform_change="platform_change">
            <template>
                <div class="btns">
                    <el-link type="primary" @click.stop="openQr()">
                        点击授权</el-link
                    >
                    <el-link type="primary" @click.stop="shoFX = true">
                        复制链接</el-link
                    >
                </div>
            </template>
        </PLATFORM>
        <el-dialog
            title="添加POI地址"
            :visible="poi_set.authorizedUserId !== 0"
            :destroy-on-close="true"
            width="500px"
        >
            <POI_SELECT ref="poi" :key="Math.random()" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="poi_set.authorizedUserId = 0"
                    >取 消</el-button
                >
                <el-button type="primary" @click="setPoi">确 定</el-button>
            </span>
        </el-dialog>
        <div class="user_content">
            <div class="table-box">
                <el-table
                    :data="list"
                    tooltip-effect="dark"
                    style="width: 100%"
                    size="small"
                    v-loading="loading"
                >
                    <el-table-column
                        fixed
                        prop="userNickname"
                        label="账号"
                        width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        show-overflow-tooltip
                        prop="id"
                        label="头像/ID"
                        width="120"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <div class="user">
                                <img :src="scope.row.headPortraitUrl" alt="" />
                                <div class="ellipsis">{{ scope.row.id }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="cookieState" label="发布权限">
                        <template slot-scope="scope">
                            <span v-if="scope.row.cookieState == 2"
                                >已授权</span
                            >
                            <span
                                @click="getSms(scope.row.id)"
                                v-else-if="
                                    scope.row.cookieState == 4 ||
                                    scope.row.cookieState == 3
                                "
                                >短信验证,点击发送短信</span
                            >
                            <span v-else>未授权</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="videoPlayCount" label="播放量">
                        <template slot-scope="scope">
                            <span>{{
                                scope.row.videoPlayCount
                                    ? scope.row.videoPlayCount
                                    : 0
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="likeCount" label="点赞">
                        <template slot-scope="scope">
                            <span>{{
                                scope.row.likeCount ? scope.row.likeCount : 0
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fansCount" label="粉丝">
                        <template slot-scope="scope">
                            <span>{{
                                scope.row.fansCount ? scope.row.fansCount : 0
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shareCount" label="分享">
                        <template slot-scope="scope">
                            <span>{{
                                scope.row.shareCount ? scope.row.shareCount : 0
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userGroupList" label="所在分组">
                        <template slot-scope="scope">
                            <div>
                                <el-tag
                                    size="mini"
                                    v-for="(item, index) in scope.row
                                        .userGroupList"
                                    :key="index"
                                    >{{ item.accountGroup.groupName }}</el-tag
                                >
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="poiAddress" label="poi地址">
                        <template slot-scope="scope">
                            <span v-if="scope.row.poiAddress">{{
                                scope.row.poiAddress
                            }}</span>
                            <span v-else>无</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.state">授权成功</span>
                            <el-button v-else type="danger" size="mini" @click="reAuthority(scope.row)">重新授权</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="授权时间">
                        <template slot-scope="scope">
                            <div>
                                {{
                                    moment(scope.row.createTime).format(
                                        "YYYY-MM-DD HH:mm"
                                    )
                                }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="操作"
                        width="230px"
                        fixed="right"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="mini"
                                @click="delAuth(scope.row.id)"
                                >删除</el-button
                            >
                            <el-button
                                type="text"
                                size="mini"
                                @click="getReleaseList(scope.row)"
                                >查看</el-button
                            >
                            <el-button
                                type="text"
                                size="mini"
                                @click="poi_set.authorizedUserId = scope.row.id"
                                >增加poi地址</el-button
                            >
                            <el-button
                                type="text"
                                size="mini"
                                @click="slectItem(scope.row)"
                                >分组</el-button
                            >
                            <!-- <el-button
                                type="text"
                                size="mini"
                                @click="showAuth(scope.row.id)"
                                >授权平台派发</el-button
                            > -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page-box">
                <el-pagination
                    background
                    layout="sizes, prev, pager, next,total"
                    :page-size="where.size"
                    :total="total"
                    :current-page="where.current"
                    @current-change="pageChange"
                    :page-sizes="[10, 20, 30, 40, 50, 100]"
                    @size-change="handleSizeChange"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog
            custom-class="show_fx_ts_box"
            :visible.sync="shoFX"
            width="800px"
        >
            <div slot="title">
                <div class="fx_ts_title">
                    分享授权<span class="tis">( 支持多人授权 )</span>
                </div>
            </div>
            <div>
                <div class="fx_content">
                    <!-- 提示信息 -->
                    <div class="fx_info">
                        <!--步骤 -->
                        <div class="fx_info_box">
                            <div class="fx_info_hd">
                                <div class="info_h1">授权步骤</div>
                                <div class="info_h2">
                                    (当前仅支持pc浏览器授权)
                                </div>
                            </div>
                            <div class="steps">
                                <el-steps direction="vertical" :active="4">
                                    <el-step
                                        title="复制分享链接分享给企业员工或达人"
                                    ></el-step>
                                    <el-step
                                        title="邀请员工或达人使用电脑浏览器打开分享链接"
                                    ></el-step>
                                    <el-step
                                        title="抖音APP扫码授权成功后可于账号列表查看"
                                    ></el-step>
                                </el-steps>
                            </div>
                        </div>
                        <!-- 说明 -->
                        <div class="tis_content">
                            <div class="tis_red">
                                <div class="tis_red_h1">
                                    *请仔细阅读授权声明
                                </div>
                                <div class="tis_p">
                                    应平台规范及国家法律法规要求，当前授权模式仅支持使用PC浏览器进行扫码授权，矩阵内容发布仅支持分发企业或商家内容。平台将会定期审核，如有发现违规操作，将对当前账号进行禁用下架处理
                                </div>
                            </div>
                            <div class="support_box">
                                <div class="support_h1">授权后可支持</div>
                                <div class="support_p">
                                    <p>
                                        1.往您的账号下发布公开作品（发布内容，频次由您通过系统设置）
                                    </p>
                                    <p>
                                        2.删除您账号下的发布内容（由您通过系统操作确认）
                                    </p>
                                    <p>3.获取您的账号信息，视频数据，评论等</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 选择账号 -->
                    <div class="fx_group_select"></div>
                </div>
                <div class="group_box">
                    <div class="select_group_title">选择分组</div>
                    <el-select
                        style="width: 260px"
                        v-model="changeGroup"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请选择分组"
                    >
                        <el-option
                            v-for="item in group"
                            :key="item.value"
                            :label="item.groupName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
                <el-button type="primary" @click="copyUrl">复制链接</el-button>
            </span>
        </el-dialog>
        <el-dialog title="选择分组" :visible.sync="showGroup" width="440px">
            <div class="group-content">
                <div class="user-row">
                    当前用户：<span class="user-select">{{
                        select_user.userNickname
                    }}</span>
                </div>
                <div class="select-box">
                    <SELECT_GROUP :group="group" ref="select_group" />
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showGroup = false" size="small"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addGroup" size="small"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <el-dialog
            title="发布记录"
            :visible.sync="showReleaseList"
            width="1000px"
            :before-close="closeRelease"
        >
            <div class="table_box">
                <el-table :data="release_list" stripe style="width: 100%">
                    <el-table-column prop="title" label="标题">
                        <template slot-scope="scope">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="scope.row.title"
                                placement="top-start"
                            >
                                <div class="ellipsis">
                                    {{ scope.row.title }}{{ scope.row.title
                                    }}{{ scope.row.title }}
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="播放量" width="80px">
                        <template slot-scope="scope">
                            <div>{{ scope.row.accountVideo.playCount }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="分享数" width="80px">
                        <template slot-scope="scope">
                            <div>{{ scope.row.accountVideo.shareCount }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="点赞量" width="80px">
                        <template slot-scope="scope">
                            <div>{{ scope.row.accountVideo.diggCount }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="评论数" width="80px">
                        <template slot-scope="scope">
                            <div>{{ scope.row.accountVideo.commentCount }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="视频" width="80px">
                        <template slot-scope="scope">
                            <el-link
                                type="primary"
                                @click="openVideo(scope.row)"
                                >查看</el-link
                            >
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="状态" width="80px">
                        <template slot="header">
                            <el-dropdown @command="changeReleaseStatus">
                                <span class="el-dropdown-link">
                                    状态
                                    <!-- <i
                                        class="el-icon-arrow-down el-icon--right"
                                    ></i> -->
                                </span>
                                <!-- <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="1"
                                        >已发布</el-dropdown-item
                                    >
                                    <el-dropdown-item command="2"
                                        >锁定</el-dropdown-item
                                    >
                                    <el-dropdown-item command="3"
                                        >发布失败</el-dropdown-item
                                    >
                                    <el-dropdown-item command="4"
                                        >未绑定手机号</el-dropdown-item
                                    >
                                    <el-dropdown-item command="5"
                                        >发布中</el-dropdown-item
                                    >
                                    <el-dropdown-item command="0"
                                        >未发布</el-dropdown-item
                                    >
                                </el-dropdown-menu> -->
                            </el-dropdown>
                        </template>
                        <template slot-scope="scope">
                            <el-tag
                                type="success"
                                effect="plain"
                                size="mini"
                                v-if="scope.row.releaseState == 1"
                            >
                                已发布
                            </el-tag>
                            <el-tag
                                effect="plain"
                                size="mini"
                                v-else-if="scope.row.releaseState == 5"
                            >
                                发布中
                            </el-tag>
                            <el-tag
                                type="success"
                                effect="danger"
                                size="mini"
                                v-else
                            >
                                发布失败
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="发布时间"
                        width="160px"
                    >
                        <template slot-scope="scope">
                            {{
                                moment(scope.row.createTime).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                )
                            }}
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page_box">
                    <el-pagination
                        layout="prev, pager, next"
                        @current-change="release_page"
                        :current="release_where.current"
                        :hide-on-single-page="true"
                        :total="release_where.total"
                    >
                    </el-pagination>
                </div>
            </div>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showReleaseList = false"
                    >关闭</el-button
                >
            </span> -->
        </el-dialog>
    </div>
</template>

<script>
import {
    getState,
    getAutoUrl,
    getAuthList,
    delAuthUser,
    getCookiedLin,
    getCookieState,
    sendSms,
    upSms,
    getAutoGruopList,
    addGroup,
    getKsCode,
    setPoi,
    getReleaseList,
    reAuthority
} from "@/api/auth";

import moment from "moment";
import SELECT_GROUP from "@/components/auth/select_group";
import PLATFORM from "@/components/auth/platform";
import PROXY_AREA from "@/components/auth/proxyArea";
import vueQr from "vue-qr";

import POI_SELECT from "@/components/poi_select";

import qs from "qs";

export default {
    name: "matrix_list",
    components: { vueQr, SELECT_GROUP, PROXY_AREA, PLATFORM, POI_SELECT },
    data() {
        return {
            showPoi: true,
            shoFX: false,
            showGroup: false,
            authQr: "",
            list: [],
            total: 0, // 记录总数
            loading: true,
            where: {
                current: 1, //页码
                size: 10, //每页数量
                "orders[0].asc": false,
                "orders[0].column": "update_time",
            },
            group_id: "",
            group_where: {
                current: 1,
                size: 200,
                platformType: "",
                groupName: "",
            },
            options: [],
            type: 1,
            showQR: false, //二维码弹框
            showTaskQR: false, //授权cookie弹框
            getState: null, //轮询授权状态定时器
            authState: 0,
            qrlogo: "https://p3.douyinpic.com/aweme/100x100/aweme-avatar/mosaic-legacy_30e9b000507e8bb05ad2d.jpeg?from=4010531038", // 二维码
            shareUrl: "",
            userKey: "", //分享key
            //
            select_user: {},
            group: [],
            area: [],
            area_select: [],
            cityCode: "",
            authId: "",
            cookie_txt: "请选择代理地区",
            platform: "douyin",
            changeGroup: [],
            poi_set: {
                authorizedUserId: 0,
                poiId: "",
                poiAddress: "",
            },
            showReleaseList: false,
            release_total: 0,
            release_list: [],
            release_where: {
                authorizedUserId: "",
                current: 1,
                releaseState: 1,
                total: 0,
            },
        };
    },

    mounted() {},
    created() {
        this.getAuthList();
        this.getAutoGruopList();
    },

    methods: {
        moment,
        reAuthority(item){
            // console.log(item);
            let platform = this.platform;
            let platform_code = 0;
            switch (platform) {
                case 'douyin':
                platform_code = 1
                    break;
                case 'kuaishou':
                platform_code = 2
                    break;
            
                default:
                    
                    return;
            }
            let data = {
                id : item.id,
                platform: platform_code
            }
            reAuthority(data).then(res => {
                console.log(res);
                if(res.code == 2001){
                    this.$message.error('当前授权账号需要通过抖音重新授权')
                }else{
                    this.$message.success('授权成功')
                }
                // let code = 
                
            });
        },
        setPoi() {
            const poi = this.$refs.poi.poi;
            this.poi_set.poiId = poi.id;
            this.poi_set.poiAddress = poi.name;
            setPoi(this.poi_set).then((res) => {
                if (res.code == 200) {
                    this.$message.success("绑定成功");
                    this.poi_set.authorizedUserId = 0;
                    this.poi_set.poiId = "";
                    this.poi_set.poiAddress = "";
                }
            });
        },
        copyUrl() {
            let group_ids = this.changeGroup.toString();
            // console.log(group_ids);
            let data = {
                userGroup: group_ids,
            };
            switch (this.where.platformType) {
                case "douyin":
                    // 获取抖音授权链接
                    getState(data).then((res) => {
                        // console.log(res);
                        this.$copyText(res.data.authorizeUrl).then(
                            (e) => {
                                this.$message.success("复制成功~");
                                this.shoFX = false;
                            },
                            (err) => {
                                this.$message.error("复制失败~");
                            }
                        );
                    });
                    break;

                case "kuaishou":
                    // 获取快手授权链接
                    getKsCode(data).then((res) => {
                        // console.log(res);
                        // this.shareUrl = res.data;
                        this.$copyText(res.data).then(
                            (e) => {
                                this.$message.success("复制成功~");
                                this.shoFX = false;
                            },
                            (err) => {
                                this.$message.error("复制失败~");
                            }
                        );
                    });
                    break;

                default:
                    break;
            }
        },
        openUrl() {
            window.open(this.shareUrl);
        },
        notOpen() {
            this.$alert("暂未开放");
        },
        getReleaseList(data) {
            this.release_where.authorizedUserId = data.id;
            let release_where = this.release_where;
            console.log(data);
            const where = {
                authorizedUserId: data.id,
                "page.current": release_where.current,
                "orders[0].asc": false,
                "orders[0].column": "update_time",
                releaseState: release_where.releaseState || 1,
            };
            where.authorizedUserId = data.id;

            getReleaseList(where).then((res) => {
                let data = res.data;
                let list = data.records;
                if (list.length) {
                    this.release_list = list;
                    this.showReleaseList = true;
                    this.release_where.total = res.data.total;
                } else {
                    this.$message.error("查不到该授权用户的发布列表");
                }
            });
        },
        closeRelease() {
            this.showReleaseList = false;
        },
        release_page(current) {
            this.release_where.current = current;
            this.getReleaseList({ id: this.release_where.authorizedUserId });
        },
        changeReleaseStatus(state) {
            this.release_where.releaseState = state;
            this.release_where.current = 1;
            this.getReleaseList({ id: this.release_where.authorizedUserId });
        },
        openVideo(data) {
            let accountVideo = data.accountVideo;
            console.log(new Date());
            if (accountVideo.videoUrl) {
                window.open(accountVideo.videoUrl);
            } else {
                this.$message("此视频暂未发布！");
            }
        },
        // 切换平台
        platform_change(val) {
            // 储存分享链接字段
            this.platform = val;
            this.changeGroup = [];
            this.getAuthList();
            this.getAutoGruopList();
        },
        async getSms(uid) {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let json = { authorizedUserId: uid };
            let sendRes = await sendSms(json);
            loading.close();
            if (sendRes.code == 200) {
                this.$message.success("发送成功~");
                // 发送成功
                this.$prompt("请输入用户验证码", "短信验证", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(({ value }) => {
                    this.upSms(uid, value);
                });
            } else {
                // 发送失败
                this.$message.error("发送短信失败~");
            }
        },
        upSms(uid, code) {
            let json = { authorizedUserId: uid, code: code };
            upSms(json).then((res) => {
                if (res.code == 200) {
                    this.$message.success(res.msg);
                } else {
                    this.$message.error("短信校验失败");
                }
            });
        },

        // 分发授权
        async showAuth(uid) {
            this.showTaskQR = true;
            this.authState = 1;
            this.authId = uid;
            this.getCookie();
            // console.log(this.authId);
        },
        async getCode(res) {
            let _this = this;
            clearInterval(_this.getState);
            // this.getState = null;
            // debugger;
            let data = res;
            this.authState = 0;
            if (res.switch) {
                this.authState = 0;
                this.cookie_txt = "请选择代理地区";
                return;
            }
            this.cookie_txt = "正在生成授权二维码";
            if (res.type == 1) {
                // 数据授权

                let res = await getState({ areaCode: data.cityCode });

                if (res.code == 200) {
                    // 获取授权ID
                    this.authState = 1;
                    this.userKey = res.data.stateId;

                    this.showQR = true;
                } else {
                    // 生成授权链接失败
                    this.$message.error("生成授权链接失败");
                    this.cookie_txt = "生成失败，请从新选择代理地区";
                }
            } else if (res.type == 2) {
                this.authState = -1;
                // 发视频授权
                await this.getCookie(res.cityCode);

                console.log("发视频授权");
            }
        },
        // 关闭分发授权框
        closetaskQr() {
            clearInterval(this.getState);
            this.authQr = "";
            this.authState = -1;
            this.cookie_txt = "请选择代理地区";
        },
        // 获取授权cookie链接
        async getCookie(code) {
            let json = {
                authorizedUserId: this.authId,
                areaCode: code,
            };
            getCookiedLin(json).then((res) => {
                if (res.code == 200) {
                    this.authState = 1;
                    this.authQr = "data:image/png;base64," + res.data.qrcode;
                    this.$message.success("获得授权码成功，等待用户扫码授权");

                    // 定时轮询授权状态
                    //   console.log(res);
                    let _this = this;
                    this.getState = setInterval(function () {
                        console.log("开始轮询");
                        getCookieState({
                            token: res.data.token,
                            areaCode: "",
                        }).then((res) => {
                            let state = res.data.data.data.status;

                            _this.authState = state;
                            switch (state) {
                                case "-1":
                                    clearInterval(_this.getState);
                                    console.log("授权码异常");
                                    this.cookie_txt =
                                        "授权码异常，请联系管理员";
                                    _this.$message.error("授权码异常");
                                    break;
                                case "5":
                                    // 获取cookie授权失败
                                    // console.log("aaaa:" + _this.getState);
                                    console.log("授权失败");
                                    clearInterval(_this.getState);
                                    _this.$message.error(
                                        "获取cookie授权失败，请重新获取二维码"
                                    );
                                    break;
                                case "3":
                                    // 成功
                                    console.log("授权成功");
                                    clearInterval(_this.getState);
                                    _this.$message.success(
                                        "获取cookie授权成功！可以给此用户分发视频了"
                                    );
                                    break;
                                case "2":
                                    console.log("已扫码");
                                    // 已扫码
                                    break;
                                case "1":
                                    console.log("等待扫码");
                                    // 等待扫码
                                    break;
                                default:
                                    clearInterval(_this.getState);
                                    break;
                            }
                        });
                    }, 3000);
                } else {
                    this.$message.error("获取授权码失败，请重新获取");
                    this.cookie_txt = "生成失败，请从新选择代理地区";
                }
            });
        },
        getAutoUrl() {
            getAutoUrl().then((res) => {
                console.log(res);
            });
        },
        getAuthList() {
            this.loading = true;

            let where = this.where;
            where.platformType = this.platform;
            getAuthList(where).then((res) => {
                this.loading = false;
                this.list = res.data.records;
                // for (let index = 0; index < 8; index++) {
                //     this.list.push(res.data.records[0]);
                // }
                this.total = res.data.total;
            });
        },
        pageChange(current) {
            this.where.current = current;
            // let where = this.where;
            // where = qs.stringify(where);
            // console.log("转换后的格式：", where);

            this.getAuthList();
        },
        handleSizeChange(size) {
            // console.log(size);
            this.where.size = size;
            this.getAuthList();
        },
        delAuth(id) {
            this.$confirm(
                "删除后该账号将消失在列表中，重新授权可查看账号历史数据，确定",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {
                delAuthUser(id).then((res) => {
                    if (res.code == 200) {
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                        this.getAuthList();
                    } else {
                        this.$message.error("删除失败");
                    }
                });
            });
        },
        async openQr(val, type) {
            let platformType = this.where.platformType;
            if (platformType === "douyin") {
                getState().then((res) => {
                    if (!res.data.authorizeUrl) {
                        this.$message.error("获取授权链接失败~");
                        return;
                    }
                    this.shareUrl = res.data.authorizeUrl;
                    this.openUrl();
                });
            } else {
                getKsCode().then((res) => {
                    this.shareUrl = res.data;
                    this.openUrl();
                });
            }
        },
        closeQR() {
            this.showQR = false;
            this.userKey = "";
        },
        // 当前操作的分组
        slectItem(item) {
            this.select_user = item;
            this.showGroup = true;
            // let where = { current: 1, size: 200 };
            // getAutoGruopList(where).then((res) => {
            //     // console.log(res);
            //     if (res.code == 200) {
            //         this.group = res.data.records;
            //         this.showGroup = true;
            //     }
            // });
        },
        getAutoGruopList() {
            this.group_where.platformType = this.platform;
            getAutoGruopList(this.group_where).then((res) => {
                this.group = res.data.records;
            });
        },
        addGroup() {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.showGroup = false;
            let users = [];
            users.push(this.select_user.id);
            let gids = [];
            this.$refs.select_group.select.map((res) => {
                gids.push(res.id);
            });
            gids = gids.toString();

            let data = {
                accountList: this.select_user.id,
                groupList: gids,
            };

            let json = {
                userGroupDto: JSON.stringify(data),
            };

            addGroup(data).then((res) => {
                loading.close();
                if (res.code == 200) {
                    this.getAuthList();
                    this.$message.success("添加成功");
                } else {
                    this.$message.error("添加失败");
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .show_fx_ts_box {
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/fx_box.png);
    background-size: 100% 100%;
}

/deep/ .el-step__title {
    font-size: 12px;
    line-height: 1.4 !important;
}
/deep/ .el-step__icon.is-text {
    background-color: #1dd59b;
    color: #fff;
    border-width: 0;
}
/deep/ .el-step__head.is-finish {
    border-color: #1dd59b;
}
/deep/ .el-step__title.is-finish {
    color: #8590b3;
}
.fx_ts_title {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    .tis {
        font-size: 12px;
        padding: 0 10px;
        font-weight: normal;
    }
}
.fx_content {
    // display: flex;
    .fx_info {
        display: flex;
        .fx_info_box {
            width: 260px;
            height: 250px;
            background-color: #fff;
            border-radius: 4px;
            padding: 14px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
        }
        .fx_info_hd {
            margin-bottom: 14px;
            text-align: center;
            .info_h2 {
                font-size: 12px;
                color: #ff6168;
            }
        }
        .steps {
            flex: 1;
        }
        .tis_content {
            flex: 1;
            width: 0;
            padding: 10px 0 0 20px;
            .tis_red {
                color: #ff6168;
                margin-bottom: 30px;
            }
            .support_h1 {
                font-size: 16px;
                font-weight: bold;
                color: #000;
                margin-bottom: 8px;
            }
            .support_p {
                p {
                    margin-bottom: 4px;
                }
            }
        }
    }
}
.user-select {
    color: #ff6168;
}
.page-box {
    padding: 10px 0;
}

.user-row {
    font-size: 12px;

    line-height: 30px;
}
.qr-box {
    position: relative;
    width: 200px;
    height: 200px;
    > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #c7c7c7;
    }
}
.user_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    i {
        cursor: pointer;
    }

    .left-box {
        // padding-left: 10px;
        display: flex;
        align-items: center;
        .tbs-box {
            display: flex;
            margin-left: 5px;
            .tab_list {
                padding: 5px;
            }
        }
    }
}
.user {
    display: flex;
    img {
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 6px;
    }
}
.btns {
    display: flex;
    justify-content: space-around;
}
</style>