<style lang="less">
.el-checkbox__label {
    font-size: 12px;
}

.el-tag + .el-tag {
    margin-left: 4px;
}
</style>
<style lang="less" scoped>
.select-group {
    position: relative;
    i {
        position: absolute;
        right: 6px;
        top: 8px;
    }
}
.select-box {
    height: 28px;
    border: 1px solid #279aff;
    border-radius: 3px 3px 0px 0px;
    padding: 3px 20px 3px 4px;
    box-sizing: border-box;
    font-size: 12px;
}
.check-group {
    padding: 4px 10px;
}
.group-box {
    flex: 1;
    padding: 4px 0;
    margin: 0 -10px;
    max-height: 500px;
    overflow: auto;
    .group-list {
        margin-bottom: 2px;
        &.active {
            background-color: #f5faff;
        }
        padding: 2px 10px;
    }
}
.select-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    .check-group {
        flex: 1;
        height: 0;
        display: flex;
        flex-direction: column;
        .check-group {
            height: 100%;
            display: flex;
            flex-direction: column;
            .group-box {
                flex: 1;
                height: 0;
                overflow: auto;
            }
        }
    }
}
</style>
<template>
    <div class="select-content">
        <div class="select-group">
            <i class="el-icon-arrow-down"></i>
            <div class="select-box">
                <el-tag
                    size="mini"
                    v-for="(item, index) in select"
                    :key="index"
                    >{{ item.groupName }}</el-tag
                >
            </div>
        </div>
        <div class="check-group">
            <div class="input-box">
                <el-input
                    placeholder="请输入分组名"
                    prefix-icon="el-icon-search"
                    v-model="input"
                    size="mini"
                    @input="screen_group"
                >
                </el-input>
            </div>
            <div class="group-box">
                <div
                    class="group-list active"
                    v-for="(item, index) in group_list"
                    :key="index"
                >
                    <el-checkbox
                        v-model="item.check"
                        @change="upStatus(item)"
                        >{{ item.groupName }}</el-checkbox
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "selectGruop",
    props: ["group"],
    data() {
        return {
            input: "",
            select: [],
            group_list: this.group,
            group_where: {
                current: 1,
                size: 200,
            },
        };
    },

    mounted() {
        this.check_select();
    },

    methods: {
        upStatus(item) {
            if (item.check) {
                this.select.unshift(item);
            } else {
                let index = this.select.findIndex(
                    (list) => list.id === item.id
                );
                this.select.splice(index, 1);
            }
        },
        check_select() {
            this.group.forEach((item) => {
                let index = this.select.findIndex(
                    (list) => list.id === item.id
                );
                if (index >= 0) {
                    this.$set(this.group[index], "check", true);
                }
            });
        },
        screen_group(val) {
            if (val) {
                let screenList = this.group.filter((item) => {
                    const reg = new RegExp(val);
                    return reg.test(item.groupName);
                });
                this.group_list = screenList;
            } else {
                this.group_list = this.group;
            }
        },
    },
};
</script>