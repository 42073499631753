<template>
    <div class="poi_select_box">
        <div class="label">关联地址</div>
        <el-autocomplete
            style="width: 100%"
            v-model="add"
            :fetch-suggestions="remoteMethod"
            placeholder="请输入位置关键字"
            :trigger-on-focus="false"
            @select="selected"
        >
            <template slot-scope="{ item }">
                <div style="padding: 6px 0; line-height: 1.3">
                    <div class="name">
                        {{ item.poi_name }}
                    </div>
                    <span
                        style="font-size: 12px; color: #a7a7a7"
                        class="addr"
                        >{{ item.address }}</span
                    >
                </div>
            </template>
        </el-autocomplete>
    </div>
</template>

<script>
import { searchPoi } from "@/api/publish";
export default {
    name: "PoiSelect",

    data() {
        return {
            add: "",
            poi: {
                id: 0,
                name: "",
            },
        };
    },

    mounted() {},

    methods: {
        remoteMethod(query, cb) {
            if (query != "") {
                let json = {
                    keyword: query,
                    cursor: 1,
                };
                searchPoi(json).then((res) => {
                    this.adds = res.data.pois;
                    res.data.pois.forEach((k, i) => {
                        k.value = k.poi_name;
                    });
                    cb(res.data.pois);
                });
            }
        },
        selected(item) {
            this.poi.id = item.poi_id;
            this.poi.name = item.poi_name;
        },
    },
};
</script>

<style lang="less" scoped>
.poi_select_box {
    display: flex;
    align-items: center;
    .label {
        flex: 0 0 5em;
    }
}
</style>