<template>
    <div class="platform">
        <div class="platform_group">
            <div
                class="platform_list"
                v-for="(item, index) in platform_list"
                :key="index"
            >
                <div
                    class="btn-box"
                    :class="item.type == platform_select ? 'active' : ''"
                    @click="platform_tab(item.type)"
                >
                    <div class="platform_logo" :class="item.class"></div>
                    <transition name="el-zoom-in-top">
                        <div class="btn" v-show="platform_select == item.type">
                            <slot></slot>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Platform",
    props: {
        platform: {
            default: "douyin",
        },
    },
    data() {
        return {
            platform_select: this.platform,
            platform_list: [
                {
                    type: "douyin",
                    class: "dy",
                },
                {
                    type: "kuaishou",
                    class: "ks",
                },
                {
                    type: "xigua",
                    class: "xg",
                },
                {
                    type: "xiaohongsu",
                    class: "xhs",
                },
                {
                    type: "toutiao",
                    class: "tx",
                },
            ],
        };
    },

    mounted() {},

    methods: {
        platform_tab(val) {
            this.platform_select = val;
            this.$emit("platform_change", val);
        },
    },
};
</script>

<style lang="less" scoped>
.platform {
    text-align: center;
    margin-bottom: 20px;
    &_group {
        display: flex;
    }
    &_list {
        flex: 1;
        display: flex;
        justify-content: center;
    }
    .btn {
        .iconfont {
            font-size: 12px;
        }
    }
    .platform_logo {
        width: 80px;
        height: 35px;
        background-image: url(../../assets/images/juzhen/list.jpg);
        background-size: cover;
    }
    .ks {
        background-position-y: -35px;
    }
    .xg {
        background-position-y: -67px;
    }
    .xhs {
        background-position-y: -100px;
    }
    .tx {
        background-position-y: -135px;
    }
    .btn-box {
        padding: 20px;
        border-radius: 4px;
        width: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
            .btn {
                display: block;
            }
        }
        .btn {
            // display: none;
            margin-top: 10px;
            font-size: 12px;
            width: 100%;
        }

        &.active {
            border: 1px solid #409eff;
        }
    }
}
</style>