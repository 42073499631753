<template>
    <!-- <el-cascader
        v-model="area_select"
        :disabled="disabled"
        :options="area"
        :props="{
            value: 'areaCode',
            label: 'name',
        }"
        @change="changeArea"
    ></el-cascader> -->
    <div>
        <div class="switch-box">
            <div>是否开启IP代理</div>
            <div>
                <el-switch v-model="ip_switch" @change="changeIP"> </el-switch>
            </div>
        </div>
        <el-cascader
            v-if="ip_switch"
            v-model="area_select"
            :disabled="disabled"
            :options="area"
            :props="{
                value: 'areaCode',
                label: 'name',
            }"
            @change="changeArea"
        ></el-cascader>
        <!-- 
        <el-select
            v-model="area_select"
            placeholder="请选择"
            @change="changeArea"
        >
            <el-option
                v-for="item in area"
                :key="item.value"
                :label="item.name"
                :value="item.areaCode"
            >
            </el-option>
        </el-select> -->
    </div>
</template>

<script>
import { getIp } from "@/api/auth";
function setAreaName(data) {
    if (!data) return;
    data.forEach((item) => {
        let name = item.cityName ? item.cityName : item.provinceName;
        item.name = name;
        if (!item.children) return;
        setAreaName(item.children);
    });
    return data;
}
export default {
    name: "proxyArea",
    props: ["type"],
    data() {
        return {
            ip_switch: false,
            // 查询状态
            disabled: false,
            // 代理地址数据
            area: [],
            // 当前选中的所有编码
            area_select: "",
            // 地区编码
            cityCode: "",
        };
    },

    mounted() {},

    created() {
        this.showAuth();
    },
    methods: {
        changeIP() {
            let json = {};
            if (!this.ip_switch) {
                json = {
                    type: this.type,
                    cityCode: "",
                    switch: this.ip_switch,
                };
                // console.log(json);
            } else {
                json = {
                    switch: this.ip_switch,
                };
            }

            this.$emit("cityCode", json);
        },
        // 分发授权
        async showAuth() {
            this.disabled = true;
            let area = await getIp({ proxy: true });
            //
            if (area.code != 200) {
                this.$message.error("获取代理数据失败！");
                return;
            }
            let data = await setAreaName(area.data);
            this.disabled = false;
            // console.log(data);
            this.area = data;
        },
        // 选择的地区
        changeArea(area) {
            // console.log(this.area_select);
            // // 联级
            // console.log(area);
            let cityCode = area[area.length - 1];
            let json = { type: this.type, cityCode: cityCode };
            // console.log(json);
            this.$emit("cityCode", json);
        },
    },
};
</script>

<style lang="less" scoped>
.switch-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>