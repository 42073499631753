import request from '@/utils/request'

// 获取授权ID
export function getState(data) {
    return request({
        url: '/api/app/douyinresult/getState',
        // url: '/abckdl/douyin/douyinOauthCode',
        // headers: {
        //     "Content-Type": "application/x-www-form-urlencoded",
        // },
        method: 'GET',
        params: { ...data }
    })
}


export function getAutoUrl() {
    return request({
        url: '/accountdata/getauthorizationURL',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
    })
}
export function setAuth(url, params) {
    return request({
        url,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
        params
    })
}

// 获取授权列表
export function getAuthList(data) {
    console.log(data);
    return request({
        url: '/api/app/accountdata/page',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
        params: data
    })
}
// 删除授权用户
export function delAuthUser(id) {
    return request({
        url: `/api/app/accountdata/delete/${id}`,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'POST',
    })
}


// 抖音-用户组管理

// 创建抖音授权账号分组
export function createAutoGruop(data) {
    return request({
        url: '/api/app/group',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'POST',
        data
    })
}
// 修改抖音授权账号分组
export function editAutoGruop(data) {
    return request({
        url: '/api/app/group/update',
        method: 'POST',
        data
    })
}
// 获取授权账号分组记录查询
export function getAutoGruopList(data) {
    return request({
        url: '/api/app/group/page',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
        params: data
    })
}

// 删除授权账号分组记录
export function delAutoGruopList(id) {
    return request({
        url: `/api/app/group/delete/${id}`,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'POST'
    })
}
// 获取授权cookie
export function getCookiedLin(data) {
    return request({
        url: '/api/app/accountdata/getCookieAuthorizeQRCode',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
        params: data
    })
}
// 查询cookie状态
export function getCookieState(data) {
    console.log(data);
    return request({
        url: '/api/app/accountdata/getQRCodeState',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
        params: data
    })
}
// 获取短信
export function sendSms(data) {
    return request({
        url: '/api/app/accountdata/sendDouyinSecurityCode',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
        params: data
    })
}

// 提交验证码
export function upSms(data) {
    return request({
        url: '/api/app/accountdata/validateDouyinSecurityCode',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
        params: data
    })
}
// 授权账号添加到用户组
export function addGroup(data) {
    return request({
        url: '/api/app/usergroup/addAccountToGroup',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
// 通过分组ID查询账号列表 groupUser
export function groupUser(data) {
    return request({
        url: '/api/app/usergroup/getAccountListByGroupId',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: data
    })
}
// 获取代理IP

export function getIp(data) {
    return request({
        url: '/api/app/districtInfo/getDistrictInfoTreeList',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: data
    })
}
// 获取快手授权链接/api/app/accountdata/kwaiScanAuthorization
export function getKsCode(data) {
    return request({
        url: '/api/app/accountdata/kwaiScanAuthorization',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: data
    })
}
// 移除分组里的用户
export function removeUser(params) {
    return request({
        url: '/api/app/usergroup/deleteUserToGroup',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params
    })
}
// 移除分组里的用户
export function setPoi(data) {
    return request({
        url: '/api/app/accountdata/bindPoiAddress',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
export function getReleaseList(data) {
    return request({
        url: '/api/app/videorelease/getAuthorizedUserReleaseList',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
// 手动授权
export function reAuthority(data) {
    return request({
        url: data.platform == 1 ? '/service/platform/handleaccesstoken' : '/service/platform/kuaishouHandleRefreshToken',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
            id: data.id
        }
    })
}
